import React, { Component } from 'react';
import { Link } from "react-router-dom";
import Users from "../../utils/api/users";

class UserCreateEditForm extends Component {

  constructor(props) {
    super(props);
    this.state = {
      success: null,
      errorMsg: '',
      formName: 'Create User',
      user: {
        _id: '',
        firstName: '',
        lastName: '',
        email: '',
        password: '',
        userName: '',
        phone: ''
      }
    }
    if (props.location.state) {
      this.state = {
        success: null,
        errorMsg: '',
        formName: props.location.state.formName,
        user: props.location.state.user,
      }
    }
  }

  saveChangesHandler = (event) => {
    event.preventDefault()
    // console.log('saveChangesHandler', this.state)
    if (!this.state.user.email || !this.state.user.userName || !this.state.user.password) {
      this.setState({
        errorMsg: 'Empty fields'
      })
      return
    }
    if (!this.state.user._id) {
      const user = this.state.user
      delete user._id
      Users.create({ ...this.state.user })
        .then(data => {
          data && data.data && this.setState({
            success: true,
            errorMsg: ''
          })
          setTimeout(() => {
            this.props.history.push("/users");
          }, 1000)
        })
        .catch(error => {
          console.log('error', error)
          this.setState({
            success: null,
            errorMsg: error.toString()
          })
        })
    } else {
      Users.update(this.state.user._id, this.state.user)
        .then(data => {
          data && data.data && this.setState({
            success: true,
            errorMsg: ''
          })
          setTimeout(() => {
            this.props.history.push("/users");
          }, 1000)
        })
        .catch(error => {
          console.log('error', error)
          this.setState({
            success: null,
            errorMsg: error.toString()
          })
        })
    }
  }
  handleInputChange = (event) => {
    const target = event.target;
    const prevStateUser = {...this.state.user}
    prevStateUser[target.name] = target.value
    this.setState({
      user: {
        ...prevStateUser
      }
    });
  }

  render() {
    return <main className="wrapper wrapper__form">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="page-head">
                <Link
                  to="/users"
                  className="back"
                >
                  <i className="back__icon"/>Back
                </Link>
                <h1 className="title  text-left">{this.state.formName}</h1>
              </div>
              <form className="form form--manager">
                <div className="form__inputs">
                  <div className="input__group">
                    <input
                        type="userName"
                        name="userName"
                        value={this.state.user.userName}
                        className="input__field"
                        aria-describedby="userName"
                        placeholder="User name"
                        onChange={this.handleInputChange}
                    />
                    <label className="input__label">Username</label>
                  </div>
                  <div className="input__group">
                    <input
                      type="email"
                      name="email"
                      value={this.state.user.email}
                      className="input__field"
                      aria-describedby="email"
                      placeholder="Enter Email"
                      onChange={this.handleInputChange}
                    />
                    <label className="input__label">Email</label>
                  </div>
                  <div className="input__group">
                    <input
                        type="firstName"
                        name="firstName"
                        value={this.state.user.firstName}
                        className="input__field"
                        aria-describedby="firstName"
                        placeholder="Enter Name"
                        onChange={this.handleInputChange}
                    />
                    <label className="input__label">Fist Name</label>
                  </div>
                  <div className="input__group">
                    <input
                        type="lastName"
                        name="lastName"
                        value={this.state.user.lastName}
                        className="input__field"
                        aria-describedby="lastName"
                        placeholder="Enter Last name"
                        onChange={this.handleInputChange}
                    />
                    <label className="input__label">Last Name</label>
                  </div>
                  <div className="input__group">
                    <input
                        type="phone"
                        name="phone"
                        value={this.state.user.phone}
                        className="input__field"
                        aria-describedby="phone"
                        placeholder="Enter phone number"
                        onChange={this.handleInputChange}
                    />
                    <label className="input__label">Phone Number</label>
                  </div>
                  <div className="input__group">
                    <input
                      type="password"
                      name="password"
                      value={this.state.user.password}
                      onChange={this.handleInputChange}
                      className="input__field"
                      aria-describedby="password"
                      placeholder="Enter Password" />
                    <label className="input__label">Password</label>
                  </div>
                </div>
                <div className="form__controls form__controls--bordered">
                  { this.state.success && <div className="form__status form__status--success">Saved</div> }
                  { this.state.errorMsg && <div className="form__status form__status--error">{this.state.errorMsg}</div>}
                  <button
                    onClick={this.saveChangesHandler}
                    className="btn btn__primary btn__primary--save">
                    Save Changes
                  </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </main>
  }
}

export default UserCreateEditForm;
