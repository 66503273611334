import React, { Component } from "react";
import { format } from "date-fns";

import Auth from "../../utils/api/auth";

class NotificationListItem extends Component {

  constructor(props) {
    super(props);
    this.state = {     
      isActive: ''
    }
  }

  isUserAdmin = () => {
    const userInfo = Auth.getLoggedUserInfo();
    return userInfo.role === 'admin';
  }

  render() {
    return <div className={'grid__item' + this.state.isActive}>
      <div className="item__number">
        {this.props.itemNumber}
      </div>
      <div className="item__content">
        <div className="item__tile">
          <div className="item__name">{this.props.data.location}</div>
          <div className="item__name">{this.props.data.freezer}</div>
          <div className="item__time">{format(new Date(this.props.data.sentDateTime), 'dd.MM.yyyy HH:mm:ss')}</div>         
          <div className="item__name">{this.props.data.typeMessage}</div>
          <div className="item__name">{this.props.data.system}</div>
          { this.isUserAdmin() &&
                      <button
                        onClick={() => this.props.handleRemoveChild(this.props.data._id)}
                        style={{margin: 'auto 0'}}
                        className="btn btn__action btn__action--delete"
                      />
                    }
        </div>
      </div>
    </div>
  }
}

export default NotificationListItem
