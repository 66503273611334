import React, { Component } from 'react';
import Users from "../../utils/api/users";

const divStyle = {
  display: 'flex',
  minWidth: '14em',
  alignItems: 'left',
  paddingLeft: '10px',
  
}

const divStyle2 = {
  display: 'flex',
  minWidth: '14em',
  alignItems: 'left',
  padding: '1em',
  paddingRight: '1em',
  flexDirection: 'row',
  float: 'left'
}
class UserList extends Component {

  constructor(props) {
    super(props);

    this.state = {
      users: []
    }
  }
  componentDidMount() {
    this.fetchData();
  }

  componentDidUpdate() {
    this.fetchData();
  }

  fetchData = () => {
    Users.fetchItems()
      .then(data => {
        if (data) {
          this.setState( {
            users: data.data.map(item => { return {
              _id: item._id,
              email: item.email,
              userName: item.userName,
              phone: item.phone,
              firstName: item.firstName,
              lastName: item.lastName
            }
            })
          })
        } else {

        }
      })
  }

  handleAddBtnClick = () => {
    this.props.history.push("/users/add");
  }

  handleEditBtnClick = (user) => {
    // console.log('handleEditBtnClick', user._id);
    this.props.history.push(`/users/${user._id}`, {user: user, formName: 'Edit Manager'} );
  }

  handleDeleteBtnClick = (user) => {
    // console.log('handleDeleteBtnClick', user);
    Users.delete(user._id).then(() => {
      this.fetchData();
    })
  }

  render() {
    return <main className="wrapper wrapper__form">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="page-head">
              <h1 className="title text-left">Managers</h1>
              <button onClick={this.handleAddBtnClick} className="btn btn__primary btn__primary--add">Add</button>
            </div>
            <ul className="list-unstyled managers__list">
              { this.state.users.length
                ? this.state.users.map(item => {
                  return (
                    <li className="managers__item" key={item._id}>
                      <div className="managers__userName" style={{color: 'red', fontWeight: 'bold', fontSize: 1.1 + 'em'}}>{ item.userName }</div>
                      <div className="managers__email" >{ item.email }</div>                     
                      <div className="managers__actions">
                        <button onClick={() => this.handleEditBtnClick(item)} className="btn btn__action btn__action--edit" />
                        <button onClick={() => this.handleDeleteBtnClick(item)} className="btn btn__action btn__action--delete" />
                      </div>
                    </li>
                  )
                })
              : <li className="managers__item">
                  <div className="managers__email">No users avaliable</div>
                </li>
              }
            </ul>
          </div>
        </div>
      </div>
    </main>
  } 
}


export default UserList;
