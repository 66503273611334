import React from "react";
import { Route, Redirect } from "react-router-dom";
import Auth from "../utils/api/auth";

export const ProtectedRoute = ({component: Component, ...rest}) => {
  return (
    <Route {...rest}
      render={props => {
        Auth.props = props
        if (Auth.isAuthorized()) {
          return <Component {...props} />
        } else {
          return <Redirect to={{
            pathname: '/',
            state: {
              from: props.location
            }
          }}
          />
        }
      }}
    />
  )
}
