import React, { Component } from 'react';
import Auth from '../../utils/api/auth';

class LoginPage extends Component {

  state = {
    errorMsg: '',
    email: '',
    password: ''
  }

  constructor() {
    super();
  }

  componentDidMount() {
  }

  handleLoginClick = (event) => {
    event.preventDefault()
    Auth.logIn(this.state.email, this.state.password)
      .then(data => {
        if (data) {
          this.setState({
            errorMsg: false
          });
          localStorage.setItem('authorization', data.data.accessToken)
          this.props.history.push('/locations')
          window.location.reload();
        } else {
          this.setState({
            errorMsg:'User doesn\'t exist'
          });
        }
      })
      .catch(error => {
        this.setState({
          errorMsg: 'Server Error'
        });
      })
  }

  handleInputChange = (event) => {
    const target = event.target;
    this.setState({
      [target.name]: target.value
    });
  }

  render() {
    return <main className="wrapper wrapper__login">
      <div className="container">
        <div className="row">
          <div className="col">
            <div className="login-area">
              <h1 className="title  text-center">Login</h1>
              <form className="form form--login">
                <div className="form__inputs">
                  <div className="input__group">
                    <input
                      type="email"
                      name="email"
                      className="input__field" aria-describedby="email"
                      placeholder="Enter Email"
                      value={this.state.email}
                      onChange={this.handleInputChange}
                    />
                    <label className="input__label">Email</label>
                  </div>
                  <div className="input__group">
                    <input
                      type="password"
                      name="password"
                      className="input__field"
                      aria-describedby="password"
                      placeholder="Enter Password"
                      value={this.state.password}
                      onChange={this.handleInputChange}
                    />
                    <label className="input__label">Password</label>
                  </div>
                </div>
                <div className="form__controls">
                  { this.state.errorMsg && (
                    <div className="form__status form__status--error">
                      {this.state.errorMsg}
                    </div>
                    )
                  }
                  <button onClick={this.handleLoginClick} className="btn btn__primary btn__primary--login">
                    Login
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </main>
  }
}

export default LoginPage;
