import React, { Component } from "react";
import { Link } from "react-router-dom";
import Auth from "../../utils/api/auth"

class Header extends Component {

  componentDidMount() {
    if (!Auth.isAuthorized()) {
      Auth.logOut()
    }
  }

  logoutHandler = () => {
    Auth.logOut();
    window.location.reload();
  }

  render() {
    return Auth.isAuthorized() ? (
      <header className="header d-flex justify-content-between align-items-center">
        <div className="greeting">
          Hello, {Auth.getLoggedUserInfo().email || 'User'}
        </div>
        <nav className="nav">
          <ul className="nav__list list-unstyled d-sm-flex align-items-center">
            <li>
              <Link to="/locations">Companies</Link>
            </li>
            <li>
              <Link to="/notification">Notifications</Link>
            </li>
            {Auth.getLoggedUserInfo().role === 'admin' &&
              <li>
                <Link to="/users">Managers</Link>
              </li>
            }
            <li>
              <Link to="/" className="logout" onClick={this.logoutHandler}>
                Logout<i className="logout__icon" />
              </Link>
            </li>
          </ul>
        </nav>
        <button className="nav__button d-flex align-items-center justify-content-center flex-column d-sm-none"
          aria-label="Menu Button">
          <span className="line line__top" />
          <span className="line line__middle" />
          <span className="line line__bottom" />
        </button>
      </header>
    ) :
      <header className="header d-flex justify-content-between align-items-center">
      </header>
  }
}

export default Header
