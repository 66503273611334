import React, { Component } from "react";

import Pagination from 'react-js-pagination';

import LocationListItem from "./LocationListItem";
import Locations from "./../../utils/api/locations";

import 'bootstrap/dist/css/bootstrap.css';
class LocationList extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locations: [],
      orderBy: 'friendlyName',
      sort: 'ascending',
      search: '',
      activePage: 1,
      totalItemsCount: 1,
      totalPages: 1,
      itemsCountPerPage: 10,
      pageRangeDisplayed: 5
    }
  }

  componentDidMount() {
    this.fetchLocations()
  }

  handlePageChange(pageNumber) {
    this.setState({
      activePage: pageNumber
    }, this.fetchLocations);
  }

  searchLocation = (event) => {
    this.setState({
      search: event.target.value,
      activePage: 1
    }, this.fetchLocations);
  }

  fetchLocations = () => {
    Locations.fetchItems({
      orderBy: this.state.orderBy,
      sort: this.state.sort,
      search: this.state.search == null ? '' : this.state.search,
      page: this.state.activePage
    }).then(data => {
      // get total amount of whole range items
      setTimeout(() => {
        this.setState({
          totalItemsCount: data.data.meta.totalItems,
          totalPages: data.data.meta.totalPages
        });
      }, 0);      

      data && data.data.items && this.setState({
        locations: data.data.items.map(item => {
          return {
            _id: item._id,
            friendlyName: item.friendlyName,
            status: item.status,
            sentNotifications: item.sentNotifications | 'N/A',
            updatedAt: new Date(Math.max(...item.locationSensorData.map(e => new Date(e.lastSyncDate)))),
            allowedNotifications: item.allowedNotifications,
            sensorsGroup: item.locationSensorData
          }
        })
      })
    });
  }

  setOrderByAndSort = (orderBy, currSortState) => {
    if(orderBy) {
      this.setState({
        orderBy: orderBy
      });
    }
    if (!currSortState) {
      this.setState({
        sort: 'ascending'
      }, this.fetchLocations);
    } else if (currSortState === 'ascending') {
      this.setState({
        sort: 'descending'
      }, this.fetchLocations);
    } else if (currSortState === 'descending') {
      this.setState({
        sort: 'ascending'
      }, this.fetchLocations);
    }
  }

  render() {
    return <main className="wrapper wrapper__pharmacys">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="grid__header">
              <div></div>
              <input type="text" style={{ marginLeft: 10 + 'px' }} onChange={(e) => this.searchLocation(e)} />
            </div>
          </div>
          <div className="col"></div>
          <div className="col">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="grid__header">
              <div>#</div>
              <div className="grid__subheader">
                <div onClick={() => this.setOrderByAndSort('friendlyName', this.state.sort)} style={{ cursor: 'pointer' }}>
                  User {(this.state.sort && ((this.state.sort === 'ascending' || this.state.sort === 'descending') && this.state.sort === 'ascending' ? '↑' : '↓'))}
                </div>
                <div>Last contact</div>
                <div>Sensors</div>
                <div onClick={() => this.setOrderByAndSort('status', this.state.sort)} style={{ cursor: 'pointer' }}>
                  Status {(this.state.sort && ((this.state.sort === 'ascending' || this.state.sort === 'descending') && this.state.sort === 'ascending' ? '↑' : '↓'))}
                </div>
                <div>Notifications</div>
              </div>
            </div>
            {this.state.locations.length ? this.state.locations.map((item, index) => {
              return (
                <LocationListItem {...this.props} itemNumber={index + 1} data={item} key={item._id} callBack={() => this.fetchLocations()} />
              )
            }) :
              <div>No available locations </div>
            }
          </div>
        </div>
        <div className="row">
          <div className="col">
            <div className="grid__header">
              <div></div>
            </div>
          </div>
          <div className="col">
            <div className="item__name" style={{paddingTop: '0.58em'}}>
              page {this.state.activePage} of {this.state.totalPages} pages ( for {this.state.totalItemsCount} locations )</div>
            </div>
          <div className="col">
            <Pagination
              activePage={this.state.activePage}
              itemsCountPerPage={this.state.itemsCountPerPage}
              totalItemsCount={this.state.totalItemsCount}
              pageRangeDisplayed={this.state.pageRangeDisplayed}
              onChange={this.handlePageChange.bind(this)}
              itemClass="page-item"
              linkClass="page-link"
            />
          </div>
        </div>
      </div>
    </main>
  }


}

export default LocationList
