import API from "./API";

class Users {

  fetchItems = () => {
    return API.get('/user')
  }

  create = (data) => {
    return API.post(`/user`, data)
  }

  update = (itemID, data) => {
    return API.patch(`/user/${itemID}`, data)
  }

  delete = (itemID) => {
    return API.delete(`/user/${itemID}`)
  }
}

export default new Users()
