import API from "./API";

class Notification {
  getAllNotification = (params) => {    
    let queryParams = '';
    if(Object.keys(params).length) {      
      for(const [key, value] of Object.entries(params)){   
        queryParams += queryParams === '' ? (`?${key}=${value}`) : (`&${key}=${value}`);     
      }      
    }   
    return API.get('/notification' + queryParams);
  }

  deleteNotification = (id) => {
    return API.delete(`/notification/${id}`);
  }
}

export default new Notification()
