import API from "./API";

class Locations {
  fetchItems = (params) => {    
    let queryParams = '';
    if(Object.keys(params).length) {      
      for(const [key, value] of Object.entries(params)){   
        queryParams += queryParams === '' ? (`?${key}=${value}`) : (`&${key}=${value}`);     
      }      
    }   
    return API.get('/locations' + queryParams);
  }

  downloadLocationHistory = (ids) => {
    return API.get(
      `/locations/location-history/download?sensors=${ids.join(',')}`,
      {responseType: 'blob'}
    );
  }

  fetchSensorHistory = (id) => {
    return API.get(`/locations/sensor-history/${id}`)
  }

  downloadSensorHistory = (id) => {
    return API.get(
      `/locations/sensor-history/${id}/download`,
      {responseType: 'blob'}
      );
  }

  sendNotification = (data, ids = []) => {   
    if (ids.length) {
      return Promise.all(ids.map(id => API.get(`/locations/${id}/notifications/send`)));
    } else {
      return API.get(`/locations/${data._id}/notifications/send`)
    }
  }

  editTempLimits = (data) => {
    return API.patch(`/locations/${data._id}/temp-limits`, data)
  }

  updateNotificationTypes = (ids, notifications) => {   
    return API.patch(`/locations/notifications`, {locationsId: ids, notifications: notifications})
  }

  updateLocationName = (id, name) => {
    return API.patch(`/locations/${id}/change-sensor-name`, {newName: name});
  }

  deleteLocation = (id) => {
    return API.delete(`/locations/${id}`);
  }
}

export default new Locations()
