import API from "./API";

const authorizationKey = 'authorization'
const userStorageKey = 'user'

class Auth {
  user = {}
  props = {}

  constructor() {

    API.interceptors.request.use(req => {
      req.headers.authorization = localStorage.getItem(authorizationKey);
      return req;
    });

    API.interceptors.response.use(
      res => {
        // console.log('response interceptor', res)
        if (res && res.config.url === '/auth/sign-in') {
          localStorage.setItem(authorizationKey, res.data.accessToken)
          localStorage.setItem(userStorageKey, JSON.stringify(res.data.user))
        }
        return res
      },
      err => {
        // console.log('response interceptor error', err)
        if (err && err.response && err.response.status === 401) {
          localStorage.removeItem(authorizationKey)
          console.log(`${err.config.url} not authoraised`);
        }
        // throw err;
        this.props.history.push('/');
      }
    );
  }

  isAuthorized = () => {
    return localStorage.getItem(authorizationKey) !== null
  }

  logIn = (email, password) => {
    return API.post('/auth/sign-in', {
      "email": email,
      "password": password
    })
  }

  signUp = (email, password) => {
    return API.post('/auth/sign-up', {
      "email": email,
      "password": password
    })
  }

  logOut = () => {
    localStorage.setItem(authorizationKey, null)
    localStorage.removeItem(authorizationKey)
    localStorage.removeItem(userStorageKey)
  }

  getLoggedUserInfo = () => {
    return JSON.parse(localStorage.getItem(userStorageKey))
  }

  setProps = (props) => {
    this.props = props
  }
}

export default new Auth()
