import React, { Component } from "react";
import Locations from "./../../utils/api/locations";

class LocationSensorHistory extends Component {

  constructor(props) {
    super(props);
    this.state = {
      locationsHistory: []
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = () => {
    Locations.fetchSensorHistory(this.props.match.params.id)
      .then(res => {
        this.setState({
          locationsHistory: res.data.map(i => {
            return {
              date: new Date(i.date).toDateString(),
              lowestAirTemp: i.lowestAirTemp,
              highestAirTemp: i.highestAirTemp,
              lowestLiquidTemp: i.lowestLiquidTemp,
              highestLiquidTemp: i.highestLiquidTemp,
            }
          })
        });
      });
  }

  downloadSensorHistory = async (event, sensorId) => {
    event.preventDefault();
    Locations.downloadSensorHistory(this.props.match.params.id)
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sensor-history-${this.props.match.params.id}.csv`);
        document.body.appendChild(link);
        link.click();
      });
  }

  render() {
    return (
      <main className="wrapper wrapper__pharmacys">
        <button onClick={this.downloadSensorHistory} className="btn btn__primary">
          Download CSV
        </button>
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <div className="grid__header">
                <div>#</div>
                <div className="grid__subheader">
                  <div>Date</div>
                  <div>Lowest air temp</div>
                  <div>Highest air temp</div>
                  <div>Lowest liquid temp</div>
                  <div>Highest liquid temp</div>
                </div>
              </div>
              { this.state.locationsHistory.length ?
                this.state.locationsHistory.map((item, index) => {
                  return <div className="grid__item" key={'sensorHistory' + index}>
                    <div className="item__number">
                      { index + 1 }
                    </div>
                    <div className="item__content">
                      <div className="item__tile">
                        <div className="item__name">{ item.date.toLocaleString() }</div>
                        <div className="item__name">{ item.lowestAirTemp }</div>
                        <div className="item__name">{ item.highestAirTemp }</div>
                        <div className="item__name">{ item.lowestLiquidTemp }</div>
                        <div className="item__name">{ item.highestLiquidTemp }</div>
                      </div>
                    </div>
                  </div>
                }) :
                <div>No available records </div>
              }
            </div>
          </div>
        </div>
      </main>
    )
  }

}

export default LocationSensorHistory