import React, { Component } from "react";
import { format } from "date-fns";
import Locations from "../../utils/api/locations"
import Auth from "../../utils/api/auth";

const settingsAction = {
  sms: 0,
  pushNotification: 1,
  emailNotification: 2,
  sendNotification: 3,
  editTemperature: 4
}

class LocationListItem extends Component {

  constructor(props) {
    super(props);    
    this.state = {
      settingState: false,
      expandState: false,
      isActive: '',
      allowedNotifications: {...props.data.allowedNotifications}
    }
  }

  handleSettingsClick = () => {
    this.setState(prevState => ({
      settingState: !prevState.settingState
    }));
  }

  handleExpandClick = () => {
    this.setState(prevState => ({
      expandState: !prevState.expandState,
      isActive: !prevState.expandState ? ' active' : ''
    }));
  }

  handleHistoryOpen = (e, id) => {
    e.preventDefault();
    // console.log('handleHistoryOpen', id)
    this.props.history.push(`/locations/sensor/${id}/history`)
  }

  isUserAdmin = () => {
    const userInfo = Auth.getLoggedUserInfo();
    return userInfo.role === 'admin';
  }

  handleSettingsEvent = (event, settingType) => {
    // console.log(event, event.target.checked, settingType)
    const currentAllowedNotification = this.state.allowedNotifications
    switch (settingType) {
      case settingsAction.sms:
        currentAllowedNotification[event.target.name] = !currentAllowedNotification[event.target.name]
        this.setState( {
          allowedNotifications: currentAllowedNotification
        });
        Locations.updateNotificationTypes(
            this.props.data.sensorsGroup.map(sensor => sensor.recordId),
            {...this.state.allowedNotifications}).then(data => this.props.callBack(data)
        )
        break;
      case settingsAction.pushNotification:
        currentAllowedNotification[event.target.name] = !currentAllowedNotification[event.target.name]
        this.setState( {
          allowedNotifications: currentAllowedNotification
        });
        Locations.updateNotificationTypes(
            this.props.data.sensorsGroup.map(sensor => sensor.recordId),
            {...this.state.allowedNotifications}).then(data => this.props.callBack(data)
        )
        break;
      case settingsAction.emailNotification:
        currentAllowedNotification[event.target.name] = !currentAllowedNotification[event.target.name]
        this.setState( {
          allowedNotifications: currentAllowedNotification
        });
        Locations.updateNotificationTypes(
            this.props.data.sensorsGroup.map(sensor => sensor.recordId),
            {...this.state.allowedNotifications}).then(data => this.props.callBack(data)
        )
        break
      case settingsAction.sendNotification:
        // console.log('send notification', this.props.data, this.props.data.sensorsGroup)
        Locations.sendNotification(this.props.data, this.props.data.sensorsGroup.map(i => i.recordId)).then(data => this.props.callBack(data))
        break
      case settingsAction.editTemperature:
        // this.props.history.push("/locations/edit");
        this.props.history.push(`/locations/${this.props.data._id}/edit`, this.props.data);
        break
      default:
        console.log('smth went wrong');
    }
  }

  downloadLocationHistory = (event, locationId) => {
    event.preventDefault();
    Locations.downloadLocationHistory(this.props.data.sensorsGroup.map(i => i.recordId))
      .then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `sensor-history-${this.props.match.params.id}.csv`);
        document.body.appendChild(link);
        link.click();
      });
  }

  render() {
    return <div className={'grid__item' + this.state.isActive}>
      <div className="item__number">
        { this.props.itemNumber }
      </div>
      <div className="item__content">
        <div className="item__tile">
          <div className="item__name">{ this.props.data.friendlyName }</div>
          <div className="item__time">{ new Date(this.props.data.updatedAt).toLocaleString() }</div>
          <div className="element__area element__area--info">
            <div className="element element__info element__info--sensors">{ this.props.data.sensorsGroup.length } </div>
          </div>
          <div className="element__area element__area--status">
            { this.props.data.sensorsGroup.find(i => i.status === 'error') ?
              <div className="element element__status element__status--error"/> :
              <div className="element element__status element__status--success" />
            }
          </div>
          <div className="element__area element__area--info">
            <div className="element element__info element__info--notifications">{ this.props.data.sentNotifications }</div>
          </div>
          <div
            onClick={this.handleSettingsClick}
            className="element element__settings">
            <ul className="element__settings__list list-unstyled" style={{display: this.state.settingState ? 'block' : 'none', zIndex: 15}}>
              { this.isUserAdmin() &&
              <li>
                <label className="element__settings__parameter checkbox-parameter">
                  SMS
                  <input
                    name="sms"
                    type="checkbox"
                    checked={this.state.allowedNotifications.sms}
                    onChange={event => this.handleSettingsEvent(event, settingsAction.sms)}
                  />
                  <span className="checker">
                    <span className="checker__dot"/>
                  </span>
                </label>
              </li>
              }
              <li>
                <label className="element__settings__parameter checkbox-parameter">
                  Push Notifications
                  <input
                    name="push"
                    type="checkbox"
                    checked={this.state.allowedNotifications.push}
                    onChange={event => this.handleSettingsEvent(event, settingsAction.pushNotification)}
                  />
                  <span className="checker">
                    <span className="checker__dot"/>
                  </span>
                </label>
              </li>
              <li>
                <label className="element__settings__parameter checkbox-parameter">
                  Email
                  <input
                    name="email"
                    type="checkbox"
                    checked={this.state.allowedNotifications.email}
                    onChange={event => this.handleSettingsEvent(event, settingsAction.emailNotification)}
                  />
                  <span className="checker">
                    <span className="checker__dot"/>
                  </span>
                </label>
              </li>
              <li>
                <span onClick={event => this.handleSettingsEvent(event, settingsAction.sendNotification)} className="element__settings__parameter">
                  Send Notification
                  <i className="parameter-icon parameter-icon--notification" />
                </span>
              </li>
              <li>
                <span onClick={event => this.handleSettingsEvent(event, settingsAction.editTemperature)} className="element__settings__parameter">
                  Edit Sensors
                  <i className="parameter-icon parameter-icon--temperature" />
                </span>
              </li>
              <li>
                <span onClick={event => this.downloadLocationHistory(event)} className="element__settings__parameter">
                  Download Location data
                </span>
              </li>
            </ul>
          </div>
          <div
            onClick={this.handleExpandClick}
            className="element element__controller element__controller--arrow" />
        </div>
        <div className="item__details">
          { this.props.data.sensorsGroup.map((item, index) => {
            return <div
                onClick={(event) => this.handleHistoryOpen(event, item.recordId)}
                className="fridge__item"
                style={
                  {cursor: 'pointer'}
                }
                key={index}
            >
              <div className="fridge__name">
                { item.friendlyName || item.name }
                { item.status === 'ok' ?
                  <span className="temperature__icon temperature__icon--normal"/> :
                  <span className="temperature__icon temperature__icon--error"/>
                }
              </div>
              <div className="thermometers-list">
                <div className="thermometer__item" >
                  <div className="thermometer__name">tempair</div>
                  <div className="thermometer__value">{item.tempair} °C</div>
                </div>
                <div className="thermometer__item" >
                  <div className="thermometer__name">templiq</div>
                  <div className="thermometer__value">{item.templiq} °C</div>
                </div>
                {item.batteryVoltage &&
                <div className="thermometer__item">
                  <div className="thermometer__name">Battery voltage</div>
                  <div className="thermometer__value">{item.batteryVoltage} V</div>
                </div>
                }
                {item.lastSyncDate &&
                  <div className="lastSyncDate__item">
                    <div className="lastSyncDate__name" style = {{ float: 'left', maxWidth: 10 + 'em'}}>Last contact</div>
                    <div className="lastSyncDate__value" style = {{ fontWeight: 'bold', float: 'right', maxWidth: 16 + 'em'}}>{format(new Date(item.lastSyncDate), 'dd.MM.yyyy HH:mm:ss')}</div>
                  </div>}
              </div>
            </div>
          })}
        </div>
      </div>
    </div>
  }
}

export default LocationListItem
