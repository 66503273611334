import './webroot/css/main.css';
import LoginPage from "./components/Auth/LoginPage";
import React from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import UserList from "./components/User/UserList";
import UserCreateEditForm from "./components/User/UserCreateEditForm";
import LocationList from "./components/Location/LocationList";

import NotificationList from './components/Notification/NotificationList';

import LocationItemEdit from "./components/Location/LocationItemEdit";
import LocationSensorHistory from "./components/Location/LocationSensorHistory";
import Header from "./components/common/Header";
import {ProtectedRoute} from "./components/Protected.route";

class App extends React.Component {
  componentDidMount() {
  }

  render() {
    return <div className="App">
      <Router>
        <Header />
        <div>
          <Switch>
          <ProtectedRoute
              exact
              path="/notification"
              {...this.props }
              component={NotificationList}
            />
            <ProtectedRoute
              exact
              path="/users/add"
              {...this.props }
              component={UserCreateEditForm}
            />
            <ProtectedRoute
              exact
              path="/users/:id"
              {...this.props }
              component={UserCreateEditForm}
            />
            <ProtectedRoute
              exact
              path="/users"
              {...this.props }
              component={UserList}
            />
            <ProtectedRoute
              exact
              path="/locations/:id/edit"
              {...this.props }
              component={LocationItemEdit}
            />
            <ProtectedRoute
              exact
              path="/locations"
              {...this.props }
              component={LocationList}
            />
            <ProtectedRoute
              exact
              path="/locations/sensor/:id/history"
              {...this.props }
              component={LocationSensorHistory}
            />
            <Route
              path="/login"
              {...this.props }
              component={LoginPage}
            />
            <Route
              path="/"
              {...this.props}
              component={LoginPage}
            />
            <Route
              path="*"
              {...this.props}
              component={LoginPage}
            />
          </Switch>
        </div>
      </Router>
    </div>
  }
}

export default App;
