import React, { Component } from "react";
import {Link} from "react-router-dom";
import Locations from "../../utils/api/locations";
import Auth from "../../utils/api/auth";

class LocationItemEdit extends Component {

  constructor(props) {
    super(props);
    this.state = {
      _id: props.history.location.state._id,
      items: props.history.location.state.sensorsGroup,
      error: '',
      success: '',
      messageIndex: undefined
    }
  }

  handleInputChange = (event, item) => {
    event.preventDefault();
    const target = event.target;
    const stateToUpdate = this.state.items.find(i => i.name === item.name)
    const stateToIndex = this.state.items.findIndex(i => i.name === item.name)
    const updatePath = target.name.split('.')
    stateToUpdate.limits[updatePath[0]][updatePath[1]] = target.value
    const updateStateItems = this.state.items
    updateStateItems[stateToIndex] = stateToUpdate
    this.setState({
      items: updateStateItems,
      error: '',
      success: '',
      messageIndex: this.state.messageIndex
    });
  }

  handleNameInputChange = (event, item) => {
    event.preventDefault();
    const target = event.target;
    const stateToUpdate = this.state.items.find(i => i.name === item.name)
    const stateToIndex = this.state.items.findIndex(i => i.name === item.name)
    stateToUpdate.friendlyName = target.value
    const updateStateItems = this.state.items
    updateStateItems[stateToIndex] = stateToUpdate
    this.setState({
      items: updateStateItems,
      error: '',
      success: ''
    });
  }

  handleSaveClick = (event, index) => {
    event.preventDefault();
    Locations.updateLocationName(this.state.items[index].recordId, this.state.items[index].friendlyName)
      .then(data => {
        if (data.data.statusCode === 200) {       
          // restrict unused data for response to backend  
          delete this.state.items[index].liquidTemps;         
          Locations.editTempLimits({_id: this.state.items[index].recordId, ...this.state.items[index]})
            .then(data => {
              if (data.data.statusCode === 200) {
                this.setState({
                  error: '',
                  success: data.data.message,
                  messageIndex: index
                })
              } else if (data.data.statusCode === 204) {
                this.setState({
                  error: data.data.message,
                  success: '',
                  messageIndex: index
                })
              }
            })
            .catch(error => {
              throw error;
            });
        }
      })
      .catch(error => {
        this.setState({
          error: 'Error' + error.toString(),
          success: '',
          messageIndex: index
        })
      });
  }

  handleSensorDelete = (event, id) => {
    event.preventDefault();
    if (window.confirm("This action will delete all related data to sensor! Are sure?")) {
      Locations.deleteLocation(id)
        .then(res => {
          if (res.status === 200) {
            window.alert("All sensor data deleted successfully!");
            this.props.history.push(`/locations`);
          } else {
            throw res;
          }
      }).catch(e => {
        window.alert('Some error occurred');
        console.log(e);
      });
    }
  }

  isUserAdmin = () => {
    const userInfo = Auth.getLoggedUserInfo();
    return userInfo.role === 'admin';
  }
  
  render() {
    return <main className="wrapper wrapper__form">
      <div className="container-fluid">
        <div className="row">
          <div className="col">
            <div className="page-head">
              <Link to="/locations" className="back">
                <i className="back__icon" />Back
              </Link>
              <h1 className="title  text-left">Temperature Limits</h1>
            </div>
            {
              this.state.items.map((item, index) => { return (
                <form className="form form--limits" key={item.name + index}>
                  <div className="form__name" style={{display: 'flex', justifyContent: 'space-between'}}>
                    <div className="fridge__name" style={{minWidth: '80%', alignItems: 'center'}}>
                      <input
                        className="input__field"
                        type="text"
                        value={item.friendlyName !== undefined ? item.friendlyName : item.name}
                        onChange={(event) => this.handleNameInputChange(event, item)}
                        placeholder={item.name}
                      />
                      <span className="temperature__icon temperature__icon--default"/>
                    </div>
                    { this.isUserAdmin() &&
                      <button
                        onClick={(e) => this.handleSensorDelete(e, item.recordId)}
                        style={{margin: 'auto 0'}}
                        className="btn btn__action btn__action--delete"
                      />
                    }
                  </div>
                  <div className="form__group__label">
                    Air Temperature Limits
                  </div>
                  <div className="form__inputs form__inputs--inline">
                    <div className="input__group input__group--temperature">
                      <input
                        type="text"
                        name="tempair.min"
                        value={item.limits.tempair.min}
                        placeholder="0"
                        onChange={(event) => this.handleInputChange(event, item)}
                        className="input__field"
                        aria-describedby="air_max"
                      />
                      <label className="input__label">Min</label>
                    </div>
                    <div className="input__group input__group--temperature">
                      <input
                        type="text"
                        name="tempair.max"
                        value={item.limits.tempair.max}
                        placeholder="0"
                        onChange={(event) => this.handleInputChange(event, item)}
                        className="input__field"
                        aria-describedby="air_max"
                      />
                      <label className="input__label">Max</label>
                    </div>
                  </div>
                  <div className="form__group__label">
                    Liquid Temperature Limits
                  </div>
                  <div className="form__inputs form__inputs--inline">
                    <div className="input__group input__group--temperature">
                      <input
                        type="text"
                        name="templiq.min"
                        value={item.limits.templiq.min}
                        placeholder="0"
                        onChange={(event) => this.handleInputChange(event, item)}
                        className="input__field"
                        aria-describedby="air_max"
                      />
                      <label className="input__label">Min</label>
                    </div>
                    <div className="input__group input__group--temperature">
                      <input
                        type="text"
                        name="templiq.max"
                        value={item.limits.templiq.max}
                        placeholder="0"
                        onChange={(event) => this.handleInputChange(event, item)}
                        className="input__field"
                        aria-describedby="air_max"
                      />
                      <label className="input__label">Max</label>
                    </div>
                  </div>
                  <div className="form__controls form__controls--bordered">
                    { this.state.success && this.state.messageIndex === index &&
                      <div className="form__status form__status--success">
                        Saved
                      </div>
                    }
                    { this.state.error && this.state.messageIndex === index &&
                      <div className="form__status form__status--error">
                        Error
                      </div>
                    }
                    <button
                      onClick={event => this.handleSaveClick(event, index)}
                      className="btn btn__primary btn__primary--save"
                    >
                      Save Changes
                    </button>
                  </div>
                </form>
              )})
            }
        </div>
      </div>
    </div>
  </main>
  }
}

export default LocationItemEdit
